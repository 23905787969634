<template>
  <v-container fluid>
    <!--<bread-crumbs />-->
    <battery-diagonstic-info @redirect="redirectBack" />
    <!-- {{ batteryInfo }} -->
    <v-card v-if="batteryInfo.tenant_id">
      <v-row class="mt-2">
        <v-col
          cols="12"
          class="pb-0"
        >
          <!-- @change="tabChanged(tab)" -->
          <v-tabs v-model="tab">
            <v-tab
              v-for="tabItem in items"
              :key="tabItem.tab"
            >
              {{ tabItem.tab }}
            </v-tab>
            <v-tab-item v-if="isPermission('battery-diagnostics.dashboard')">
              <battery-dashboard />
            </v-tab-item>
            <v-tab-item v-if="isPermission('battery-diagnostics-details.list')">
              <battery-messages-tab />
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    //BreadCrumbs: () => import("@/layouts/core/BreadCrumbs.vue"),
    BatteryDiagonsticInfo: () =>
      import("@/components/sections/BatteryDiagonsticInfo.vue"),
    BatteryMessagesTab: () => import("../tabs/BatteryMessagesTab.vue"),
    BatteryDashboard: () => import("../tabs/BatteryDashboard.vue"),
  },

  data() {
    return {
      tab: 0,
      options: {},
    };
  },
  computed: {
    ...mapGetters({
      // vehicleDetails: "vehicles/getVehicle",
      // vehicleDetailsLoading: "vehicles/isLoadingVehicle",
      batteryInfo: "batterries/batterriesItem",
    }),
    items() {
      let tab = [
        { tab: this.$t("battery_telematics"), content: "battery_telematics" },
      ];

      if (this.$admin.hasAccessTo("battery-diagnostics.dashboard")) {
        tab.unshift({
          tab: this.$t("dashboard"),
          content: "dashboard",
        });
      }
      return tab;
    },
  },

  async mounted() {
    // const id = this.$route.params.imei;
    // this.options.imei = id;
    // let imei = this.$route.params.imei;
    // await this.$store.dispatch("vehicles/show", imei);
  },

  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    redirectBack() {
      this.$store.commit("telematicDetails/CLEAR_TELEMATICDETAILS");

      //   return this.$router.push({
      //     name: "battery_diagnostics",
      //     query: this.$route.query,
      //   });
      const id = "battery_diagnostics";
      let params = {};
      this.$store.dispatch("redirect/getQueryParams", id).then((res) => {
        params = { ...res };
        if (params.date) {
          params.dateRange = params.date;
          delete params.date;
        }
        const PATH = {
          name: id,
          query: params,
        };
        this.$router.push(PATH).catch(() => {});
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.flex-boxes
  display: flex
  justify-content: space-around
  flex-wrap: wrap
  .box
    flex-basis: 15%
    margin: 0.5rem 0

    .v-card__title
      color: gray
      font-size: 1rem
      text-wrap : nowrap
    .v-card__text
      text-align : center

  .info-panel
    flex-basis: 25%
    margin: 0.5rem 0
    text-wrap: nowrap
    .v-card__text
      table
        width:100%
        font-size: 14px
      tr td:first-child
        color: gray
      tr td:last-child
        font-weight: 600

.collapse-btn
  display: flex
  justify-content: space-between
  align-items: center
</style>
